import React, { useState, useEffect } from 'react';

const quotes = [
  {
    text: "Study smarter, not harder.",
    author: "Frank Watanabe"
  },
  {
    text: "Education is not the filling of a pail, but the lighting of a fire.",
    author: "W.B. Yeats"
  },
  {
    text: "The beautiful thing about learning is that no one can take it away from you.",
    author: "B.B. King"
  },
  {
    text: "The more that you read, the more things you will know. The more that you learn, the more places you'll go.",
    author: "Dr. Seuss"
  },
  {
    text: "Tell me and I forget, teach me and I may remember, involve me and I learn.",
    author: "Benjamin Franklin"
  },
  {
    text: "Learning is not attained by chance, it must be sought for with ardor and diligence.",
    author: "Abigail Adams"
  },
  {
    text: "The capacity to learn is a gift; the ability to learn is a skill; the willingness to learn is a choice.",
    author: "Brian Herbert"
  },
  {
    text: "Education is the passport to the future, for tomorrow belongs to those who prepare for it today.",
    author: "Malcolm X"
  },
  {
    text: "Live as if you were to die tomorrow. Learn as if you were to live forever.",
    author: "Mahatma Gandhi"
  },
  {
    text: "The mind is not a vessel to be filled, but a fire to be kindled.",
    author: "Plutarch"
  }
];

function Home() {
  const [quote, setQuote] = useState({ text: "", author: "" });
  const [progress, setProgress] = useState(0);
  const [authorProgress, setAuthorProgress] = useState(0);
  const [showAuthor, setShowAuthor] = useState(false);

  useEffect(() => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote || { text: "", author: "" });
  }, []);

  useEffect(() => {
    if (!quote.text) return;

    setProgress(0);
    setAuthorProgress(0);
    setShowAuthor(false);
    
    const duration = 70;
    const steps = quote.text.length;
    
    let currentStep = 0;
    const interval = setInterval(() => {
      currentStep++;
      setProgress((currentStep / steps) * 100);
      
      if (currentStep >= steps) {
        clearInterval(interval);
        startTypingAuthor();
      }
    }, duration);

    return () => clearInterval(interval);
  }, [quote]);

  const startTypingAuthor = () => {
    setShowAuthor(true);
    const duration = 10;
    const steps = 100;
    
    let currentStep = 0;
    const interval = setInterval(() => {
      currentStep++;
      setAuthorProgress((currentStep / steps) * 100);
      
      if (currentStep >= steps) {
        clearInterval(interval);
      }
    }, duration);
  };

  return (
    <div className="container mx-auto px-4 mt-32">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold text-center text-blue-500 mb-4">Welcome to Slate Hero</h1>
        <p className="text-xl text-center text-zinc-300 max-w-2xl">
          Study well.
        </p>
        <div className="mt-32 flex flex-col items-center">
          <div 
            className="text-4xl text-center text-gray-200/80 relative overflow-visible"
            style={{ 
              fontFamily: '"chalky", sans-serif',
              fontWeight: 400,
              fontStyle: 'normal'
            }}
          >
            <span 
              className="relative"
              style={{
                color: 'rgb(229 231 235 / 0.8)',
                WebkitMaskImage: `linear-gradient(to right, black ${progress}%, transparent ${progress}%)`,
                maskImage: `linear-gradient(to right, black ${progress}%, transparent ${progress}%)`
              }}
            >
              {quote.text}
            </span>
          </div>
          {showAuthor && (
            <div 
              className="mt-4 text-2xl text-center text-gray-400/80 relative overflow-visible"
              style={{ 
                fontFamily: '"chalky", sans-serif',
                fontWeight: 400,
                fontStyle: 'normal'
              }}
            >
              <span 
                className="relative"
                style={{
                  color: 'rgb(156 163 175 / 0.8)',
                  WebkitMaskImage: `linear-gradient(to right, black ${authorProgress}%, transparent ${authorProgress}%)`,
                  maskImage: `linear-gradient(to right, black ${authorProgress}%, transparent ${authorProgress}%)`
                }}
              >
                - {quote.author}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
