import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import QuizReport from './QuizReport';
import LoadingAnimation from './LoadingAnimation';

const AssessmentHierarchy = ({ topics, onSelectionChange, quizResults = [], latestResultId, showQuizChatbox, setShowQuizChatbox }) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [expandedTopics, setExpandedTopics] = useState(() => {
    const initialExpanded = {};
    topics.forEach((_, index) => {
      initialExpanded[index] = true;
    });
    return initialExpanded;
  });
  const [selectedReport, setSelectedReport] = useState(null);
  const [loadingContentIds, setLoadingContentIds] = useState(new Set());
  const [shouldStartAnimations, setShouldStartAnimations] = useState(false);
  const loadingCountRef = useRef({});

  useEffect(() => {
    setExpandedTopics(prev => {
      const newExpanded = { ...prev };
      topics.forEach((_, index) => {
        newExpanded[index] = true;
      });
      return newExpanded;
    });
  }, [topics]);

  useEffect(() => {
    const loadingIntervals = {};

    loadingContentIds.forEach(contentId => {
      if (!loadingCountRef.current[contentId]) {
        loadingCountRef.current[contentId] = 0;
      }

      loadingIntervals[contentId] = setInterval(() => {
        loadingCountRef.current[contentId]++;
        
        if (loadingCountRef.current[contentId] >= 15) {
          clearInterval(loadingIntervals[contentId]);
          setLoadingContentIds(prev => {
            const next = new Set(prev);
            next.delete(contentId);
            return next;
          });
          delete loadingCountRef.current[contentId];
        }
      }, 600);
    });

    return () => {
      Object.values(loadingIntervals).forEach(interval => clearInterval(interval));
    };
  }, [loadingContentIds]);

  useEffect(() => {
    if (shouldStartAnimations) {
      // console.log('🎯 Starting animations after quiz close');
      const selectedContent = getSelectedContent(topics, selectedItems);
      
      Object.entries(selectedContent).forEach(([topicId, data]) => {
        data.contents.forEach(contentId => {
          // console.log(`🎯 Starting animation for content: ${contentId}`);
          startLoadingAnimation(contentId);
        });
      });

      // Reset the trigger
      setShouldStartAnimations(false);
    }
  }, [shouldStartAnimations, topics, selectedItems]);

  useEffect(() => {
    if (!showQuizChatbox) {
      // console.log('🎯 Quiz closed, triggering animations');
      setShouldStartAnimations(true);
    }
  }, [showQuizChatbox]);

  useEffect(() => {
    if (latestResultId) {
      // console.log('🎯 New quiz result detected, stopping loading animations');
      // Find the latest result
      const latestResult = quizResults.find(result => result.id === latestResultId);
      if (latestResult?.contentScores) {
        // Stop animations for all content IDs in the result
        Object.keys(latestResult.contentScores).forEach(contentId => {
          // console.log(`🛑 Stopping animation for content: ${contentId}`);
          setLoadingContentIds(prev => {
            const newSet = new Set(prev);
            newSet.delete(contentId);
            return newSet;
          });
        });
      }
    }
  }, [latestResultId, quizResults]);

  const getSelectedContent = useCallback((topics, selection) => {
    const selectedStructure = {};

    // console.log('🔍 Getting selected content from:', { topics, selection });

    topics.forEach((topic) => {
      const topicSelection = selection[topic.id];
      // console.log(`🔍 Processing topic ${topic.id}:`, topicSelection);

      if (topicSelection?.content) {
        const selectedContentIds = Object.entries(topicSelection.content)
          .filter(([contentId, item]) => {
            const isValid = contentId && item && item.isSelected;
            if (!isValid) {
              // console.warn('⚠️ Invalid content entry:', { contentId, item });
            }
            return isValid;
          })
          .map(([contentId]) => contentId);

        if (selectedContentIds.length > 0) {
          // console.log(`✅ Selected content IDs for topic ${topic.id}:`, selectedContentIds);
          selectedStructure[topic.id] = {
            contents: selectedContentIds
          };
        }
      }
    });

    // console.log('📦 Final selected content structure:', selectedStructure);
    return selectedStructure;
  }, []);

  const toggleTopic = (topicIndex) => {
    setExpandedTopics(prev => ({
      ...prev,
      [topicIndex]: !prev[topicIndex]
    }));
  };

  const handleSelection = (topic, day, content) => {
    setSelectedItems(prev => {
      let newSelection = { ...prev };
      
      if (!day && !content) {
        const isTopicSelected = !prev[topic.id]?.isSelected;
        
        newSelection[topic.id] = {
          isSelected: isTopicSelected,
          content: {}
        };

        topic.days.forEach(day => {
          day.content.forEach(content => {
            if (!newSelection[topic.id].content) {
              newSelection[topic.id].content = {};
            }
            newSelection[topic.id].content[content.id] = {
              isSelected: isTopicSelected,  // Match topic's selection state
              dayId: day.id
            };
          });
        });
      } else {
        if (!newSelection[topic.id]) {
          newSelection[topic.id] = {
            isSelected: false,
            content: {}
          };
        }

        if (!newSelection[topic.id].content) {
          newSelection[topic.id].content = {};
        }

        const isContentSelected = !newSelection[topic.id].content[content.id]?.isSelected;
        newSelection[topic.id].content[content.id] = {
          isSelected: isContentSelected,
          dayId: day.id
        };

        const allContentSelected = topic.days.every(d =>
          d.content.every(c => 
            newSelection[topic.id].content[c.id]?.isSelected
          )
        );

        newSelection[topic.id].isSelected = allContentSelected;
      }

      setTimeout(() => {
        const structuredContent = getSelectedContent(topics, newSelection);
        onSelectionChange(newSelection, structuredContent);
      }, 0);
      
      return newSelection;
    });
  };

  const getScoreColor = (score) => {
    switch(score) {
      case 1: return 'bg-red-500';
      case 2: return 'bg-orange-500';
      case 3: return 'bg-green-500';
      default: return 'bg-gray-700';
    }
  };

  const getLoadingColor = (contentId) => {
    if (!loadingContentIds.has(contentId)) return '';
    
    const cyclePosition = loadingCountRef.current[contentId] % 3;
    switch(cyclePosition) {
      case 0: return 'bg-red-500';
      case 1: return 'bg-orange-500';
      case 2: return 'bg-green-500';
      default: return '';
    }
  };

  const renderScoreBoxes = (contentId) => {
    // First get the content results
    const contentResults = quizResults
      .filter(result => {
        return result.contentScores && 
               result.contentScores[contentId] &&
               result.contentScores[contentId].score;
      })
      .map(result => ({
        id: result.id,
        score: result.contentScores[contentId].score,
        timestamp: result.timestamp?.toDate?.() || new Date(result.timestamp),
        reason: result.contentScores[contentId].reason,
        report: result.report
      }))
      .sort((a, b) => b.timestamp - a.timestamp)
      .slice(0, 3);

    return (
      <div className="flex gap-1">
        {[0, 1, 2].map((index) => {
          const result = contentResults[index];
          const isLatestResult = result?.id === latestResultId;
          const isLoading = index === 0 && loadingContentIds.has(contentId);

          // If loading, shift existing results right by one position
          const shiftedResult = isLoading ? contentResults[Math.max(0, index - 1)] : result;
          
          // Use different animations for different states
          const animationClass = isLoading 
            ? 'animate-flash'  // Flashing while loading
            : isLatestResult 
              ? 'animate-fade-in'  // Simple fade in when result arrives
              : '';

          return (
            <div
              key={index}
              className={`w-4 h-4 rounded 
                ${getScoreColor(shiftedResult?.score)}
                ${shiftedResult ? 'cursor-pointer hover:opacity-80' : ''} 
                transition-all duration-300
                ${animationClass}
              `}
              title={shiftedResult ? 
                `Score: ${shiftedResult.score}/3
Date: ${new Date(shiftedResult.timestamp).toLocaleDateString()}
Reason: ${shiftedResult.reason}` 
                : 'No score yet'}
              onClick={() => shiftedResult && setSelectedReport(shiftedResult)}
              onKeyPress={(e) => e.key === 'Enter' && shiftedResult && setSelectedReport(shiftedResult)}
              role={shiftedResult ? "button" : "presentation"}
              tabIndex={shiftedResult ? 0 : -1}
            />
          );
        })}
      </div>
    );
  };

  const startLoadingAnimation = (contentId) => {
    // console.log('🟡 Starting animation for content:', contentId);
    setLoadingContentIds(prev => {
      const newSet = new Set([...prev, contentId]);
      console.log('🟡 Current animating content IDs:', Array.from(newSet));
      return newSet;
    });
  };

  const stopLoadingAnimation = (contentId) => {
    // console.log('🔵 Stopping animation for content:', contentId);
    setLoadingContentIds(prev => {
      const newSet = new Set(prev);
      newSet.delete(contentId);
      // console.log('🔵 Remaining animating content IDs:', Array.from(newSet));
      return newSet;
    });
  };

  return (
    <div className="assessment-hierarchy">
      {topics.map((topic, topicIndex) => (
        <div key={topicIndex} className="topic-item mb-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={selectedItems[topic.id]?.isSelected || false}
              onChange={() => handleSelection(topic)}
              className="mr-2"
            />
            <span 
              className="cursor-pointer flex items-center flex-1 text-gray-200"
              onClick={() => toggleTopic(topicIndex)}
            >
              {expandedTopics[topicIndex] ? 
                <ChevronDown size={20} className="text-gray-400" /> : 
                <ChevronRight size={20} className="text-gray-400" />
              }
              <span className="font-semibold ml-1 flex-1">{topic.title}</span>
            </span>
          </div>
          {expandedTopics[topicIndex] && (
            <div className="ml-6 mt-2">
              {topic.days.map((day, dayIndex) => (
                <div key={dayIndex} className="day-item mb-2">
                  {day.content.map((content, contentIndex) => (
                    <div key={contentIndex} className="content-item flex items-center mb-1">
                      <input
                        type="checkbox"
                        checked={
                          selectedItems[topic.id]?.isSelected || 
                          selectedItems[topic.id]?.content[content.id]?.isSelected || 
                          false
                        }
                        onChange={() => handleSelection(topic, day, content)}
                        className="mr-2"
                      />
                      <span className="flex-1 text-gray-300 hover:text-gray-200">{content.title}</span>
                      {renderScoreBoxes(content.id)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {/* Add report modal */}
      {selectedReport && (
        <QuizReport
          onClose={() => setSelectedReport(null)}
          structuredContent={selectedReport.selectedTopics}
          reportContent={selectedReport.report}
          timestamp={selectedReport.timestamp}
        />
      )}
    </div>
  );
};

export default AssessmentHierarchy;
