import React, { useState, useEffect } from 'react';
import { getAuth, updateEmail } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [lastLogin, setLastLogin] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser(userData);
          setFirstName(userData.firstName);
          setLastName(userData.lastName);
          setEmail(currentUser.email);
          setLastLogin(new Date(userData.lastLogin).toLocaleString());
        }
      }
    };
    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        if (email !== currentUser.email) {
          await updateEmail(currentUser, email);
        }
        await updateDoc(doc(db, 'users', currentUser.uid), {
          firstName,
          lastName,
          email
        });
        setSuccess('Profile updated successfully');
      } catch (error) {
        setError(error.message);
      }
    }
  };

  if (!user) {
    return <div className="text-gray-300">Loading...</div>;
  }

  return (
    <div className="container mx-auto mt-8 px-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-100">Profile</h2>
      {error && <p className="text-red-400 mb-4">{error}</p>}
      {success && <p className="text-green-400 mb-4">{success}</p>}
      <form onSubmit={handleSubmit} className="max-w-md">
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 text-gray-300">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 rounded bg-gray-800 border border-gray-700 text-gray-100 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="firstName" className="block mb-2 text-gray-300">First Name</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="w-full px-3 py-2 rounded bg-gray-800 border border-gray-700 text-gray-100 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="lastName" className="block mb-2 text-gray-300">Last Name</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            className="w-full px-3 py-2 rounded bg-gray-800 border border-gray-700 text-gray-100 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-gray-300">Last Login</label>
          <p className="px-3 py-2 rounded bg-gray-800 border border-gray-700 text-gray-400">{lastLogin}</p>
        </div>
        <button 
          type="submit" 
          className="bg-gradient-to-r from-blue-600/80 to-blue-700/80 text-white px-6 py-2 rounded hover:from-blue-700/90 hover:to-blue-800/90 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Update Profile
        </button>
      </form>
    </div>
  );
};

export default Profile;
