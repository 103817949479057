import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

const Navbar = ({ user, isAdmin }) => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <nav className="bg-gradient-to-b from-zinc-700 to-zinc-800 border-b border-zinc-900 shadow-[0_4px_15px_-3px_rgba(59,130,246,0.3)] relative">
      <div className="container mx-auto flex justify-between items-center p-4">
        <Link to="/" className="relative font-bold text-3xl">
          <span className="relative z-10 text-blue-500">
            Slate Hero
          </span>
        </Link>
        <div className="space-x-4">
          {user ? (
            <>
              <Link to="/courses" className="text-zinc-400 hover:text-zinc-200 transition-colors">
                Courses
              </Link>
              {isAdmin && (
                <Link to="/admin/waitlist" className="text-zinc-400 hover:text-zinc-200 transition-colors">
                  Waitlist
                </Link>
              )}
              <Link to="/profile" className="text-zinc-400 hover:text-zinc-200 transition-colors">
                Profile
              </Link>
              <Link to="/settings" className="text-zinc-400 hover:text-zinc-200 transition-colors">
                Settings
              </Link>
              <button
                onClick={handleLogout}
                className="text-zinc-400 hover:text-zinc-200 transition-colors"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="text-zinc-400 hover:text-zinc-200 transition-colors">
                Login
              </Link>
              <Link to="/join-waitlist" className="text-zinc-400 hover:text-zinc-200 transition-colors">
                Join Waitlist
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
