import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../firebase';
import useAdmin from '../hooks/useAdmin';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';
import { Navigate } from 'react-router-dom';

const AdminWaitlist = () => {
  const { isAdmin, loading } = useAdmin();
  const [waitlist, setWaitlist] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWaitlist = async () => {
      if (!isAdmin) return;
      
      try {
        const waitlistCollection = collection(db, 'waitlist');
        const waitlistSnapshot = await getDocs(waitlistCollection);
        const waitlistData = waitlistSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setWaitlist(waitlistData);
      } catch (err) {
        console.error('Error fetching waitlist:', err);
        setError('Failed to fetch waitlist. Please try again.');
      }
    };

    if (!loading && isAdmin) {
      fetchWaitlist();
    }
  }, [isAdmin, loading]);

  const activateUser = async (waitlistUser) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      const functions = getFunctions();
      const createUser = httpsCallable(functions, 'createUser');
      
      const tempPassword = Math.random().toString(36).slice(-8) + Math.random().toString(36).toUpperCase().slice(-8) + '!1';
      
      const result = await createUser({
        email: waitlistUser.email,
        password: tempPassword,
        firstName: waitlistUser.firstName,
        lastName: waitlistUser.lastName
      });

      if (result.data.success) {
        await deleteDoc(doc(db, 'waitlist', waitlistUser.id));
        setWaitlist(waitlist.filter(item => item.id !== waitlistUser.id));
      } else {
        throw new Error(result.data.error || 'Failed to create user');
      }
    } catch (error) {
      console.error('Error activating user:', error);
      alert(`Error activating user: ${error.message}`);
    }
  };

  if (loading) {
    return <div className="text-gray-300">Loading...</div>;
  }

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  if (error) {
    return <div className="text-red-400">Error: {error}</div>;
  }

  if (waitlist.length === 0) {
    return <p className="text-gray-300">No users in the waitlist.</p>;
  }

  return (
    <div className="container mx-auto mt-8 px-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-100">
        Waitlist Management
      </h2>
      <ul className="space-y-4">
        {waitlist.map(user => (
          <li key={user.id} className="mb-4 p-4 rounded-lg border border-gray-700 bg-gray-800/50">
            <div className="space-y-2">
              <p className="text-gray-300">
                <span className="font-semibold text-gray-200">Email:</span> {user.email}
              </p>
              <p className="text-gray-300">
                <span className="font-semibold text-gray-200">Name:</span> {user.firstName} {user.lastName}
              </p>
              <p className="text-gray-300">
                <span className="font-semibold text-gray-200">Joined:</span> {new Date(user.createdAt).toLocaleString()}
              </p>
              <button
                onClick={() => activateUser(user)}
                className="mt-2 bg-gradient-to-r from-green-600/80 to-green-700/80 text-white px-4 py-2 rounded hover:from-green-700/90 hover:to-green-800/90 transition duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                Activate User
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminWaitlist;
