import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';

const Settings = () => {
  const [settings, setSettings] = useState({
    preferredLLM: 'gemini', // default value
  });
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  const auth = getAuth();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          setLoading(false);
          return;
        }

        const userSettingsRef = doc(db, 'userSettings', user.uid);
        const userSettingsDoc = await getDoc(userSettingsRef);
        
        if (userSettingsDoc.exists()) {
          setSettings(userSettingsDoc.data());
        } else {
          const defaultSettings = {
            preferredLLM: 'gemini',
          };
          
          try {
            await setDoc(userSettingsRef, defaultSettings);
            setSettings(defaultSettings);
          } catch (error) {
            console.error('Error creating default settings:', error);
            setMessage('Error creating default settings');
          }
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
        setMessage('Error loading settings');
      } finally {
        setLoading(false);
      }
    };

    if (auth.currentUser) {
      fetchSettings();
    }
  }, [auth.currentUser]);

  const handleSettingChange = async (setting, value) => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No user logged in');

      const newSettings = { ...settings, [setting]: value };
      
      await setDoc(doc(db, 'userSettings', user.uid), newSettings);
      setSettings(newSettings);
      setMessage('Settings updated successfully');
      
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      console.error('Error updating settings:', error);
      setMessage('Error updating settings');
    }
  };

  if (loading) return <div className="container mx-auto mt-8 px-4 text-gray-300">Loading settings...</div>;

  if (!auth.currentUser) return <div className="container mx-auto mt-8 px-4 text-gray-300">Please log in to view settings.</div>;

  return (
    <div className="container mx-auto mt-8 px-4">
      <h1 className="text-2xl font-bold mb-6 text-gray-100">Settings</h1>
      {message && (
        <div className={`mb-4 p-2 rounded ${
          message.includes('Error') 
            ? 'bg-red-900/50 text-red-300 border border-red-700' 
            : 'bg-blue-900/50 text-blue-300 border border-blue-700'
        }`}>
          {message}
        </div>
      )}
      
      <div className="max-w-md">
        <div className="mb-6">
          <label className="block text-gray-200 font-bold mb-2">
            Preferred AI Model
          </label>
          <select
            value={settings.preferredLLM}
            onChange={(e) => handleSettingChange('preferredLLM', e.target.value)}
            className="w-full p-2 rounded bg-gray-800 text-gray-100 border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          >
            <option value="gemini">Gemini</option>
            <option value="claude">Claude</option>
          </select>
          <p className="mt-1 text-sm text-gray-400">
            Select your preferred AI model for chat interactions
          </p>
        </div>
      </div>
    </div>
  );
};

export default Settings; 