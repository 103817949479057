import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          const coursesRef = collection(db, 'courses');
          const q = query(coursesRef, where("userId", "==", user.uid));
          const querySnapshot = await getDocs(q);
          
          const coursesList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          
          setCourses(coursesList);
        } catch (error) {
          console.error("Error fetching courses: ", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  // if (loading) {
  //   return <div className="container mx-auto mt-8">Loading...</div>;
  // }

  return (
    <div className="container mx-auto mt-8 px-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">
          <span className="text-gray-300">Your Courses</span>
        </h1>
        <Link
          to="/create-course"
          className="bg-gradient-to-r from-blue-600 to-blue-700 text-white px-6 py-2.5 rounded-xl hover:from-blue-700 hover:to-blue-800 transition duration-300 shadow-lg hover:shadow-blue-500/20 border border-blue-600"
        >
          Create New Course
        </Link>
      </div>
      {courses.length === 0 ? (
        <p>You haven't created any courses yet.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {courses.map(course => (
            <Link 
              key={course.id} 
              to={`/course/${course.id}`} 
              className="bg-gradient-to-b from-gray-800 to-gray-900 p-6 rounded-xl shadow-lg hover:shadow-blue-500/10 transition duration-300 border border-gray-700 hover:border-gray-600"
            >
              <h2 className="text-xl font-semibold text-gray-100 mb-2">{course.title}</h2>
              <p className="text-gray-400">{course.description}</p>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Courses;
